<template>
  <div>
    <section class="page-title text-center">
      <div class="container">
        <div class="content-box">
          <h1>{{ $getByLang(name) }}</h1>
        </div>
      </div>
    </section>

    <!--SECTION START-->
    <section style="background-color: white;padding-top: 20px;">
      <div class="container ">
        <div class="mb-4">
          <img
            style="max-height: 400px;width: 100%;"
            :src="baseUploadURL2 + image"
            alt=""
          />
        </div>
        <div class="mb-4 mt-4">
          <div>
            <br />
            <h3>{{ $t('Massage Details') }}:</h3>
            <p>
              {{ $getByLang(body) }}
            </p>
          </div>
          <div>
            <h3>{{ $t('Price') }}:</h3>
            <div style="padding-left: 20px">
              <ul>
                <li v-for="itm of prices" :key="itm.id">
                  {{ itm.minutes }} minutes = {{ itm.price }}€
                  {{
                    itm.recommended == 1 ? '(' + $t('recommended') + ')' : ''
                  }}
                  <p style="font-size: 10px;color: #666;line-height: 15px;">
                    {{ itm.services.map((el) => $getByLang(el.name)) }}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <p class="text-center">
      <!-- <router-link
        :to="$getLink() + 'booking/' + slug"
        class="btn btn-primary"
        style="height: 60px;font-size: 25px;"
        >{{ $t('Appointment') }}</router-link
      > -->
      <a
        href="https://salonkee.lu/salon/egypta-massage-bereldange?lang=fr"
        target="_blank"
        class="btn btn-primary"
        style="height: 60px; font-size: 25px"
      >
        {{ $t("Appointment") }}
      </a>
    </p>
    <p class="text-center">
      <router-link
        :to="$getLink() + 'gift/' + slug"
        class="btn btn-danger"
        style="height: 60px; font-size: 25px;"
        >{{ $t('Gift Voucher') }}</router-link
      >
    </p>
    <!--SECTION END-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      baseUploadURL2: this.$baseUploadURL2,
      image: null,
      name: null,
      id: null,
      body: null,
      slug: null,
      prices: null,
    };
  },

  mounted() {
    const slug = this.$route.params.slug;
    if (slug) {
      this.$http2.get(`massage/getById/${slug}`).then(
        (res) => {
          if (res.data) {
            this.name = res.data.name;
            this.body = res.data.body;
            this.image = res.data.image;
            this.id = res.data.id;
            this.slug = res.data.slug;
            this.prices = res.data.prices;
          }
        },
        (err) => {
          console.log(err);
        },
      );
    }
  },
};
</script>
